<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="首页公告"
        >
        </a-page-header>
      </div>

      <a-form
        :form="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit="handleSubmit"
        style="margin-top: 24px"
      >
        <a-form-item label="公告名称：">
          <a-input
            v-decorator="[
              'title',
              {
                rules: [{ required: true, message: '请输入公告名称!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="跳转地址">
          <a-input
            v-decorator="[
              'jump',
              {
                rules: [{ required: true, message: '请输入跳转地址!' }],
              },
            ]"
          />
        </a-form-item>
       
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" html-type="submit"> 保存 </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };
  },
  mounted() {
    this.getSearchList();
  },
  methods: {
    //获取信息
    getSearchList() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios.get("/admin/config/getOne/home_announcement").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          that.form.setFieldsValue({
            title:  res.data.data.value.title,
            jump:  res.data.data.value.jump,
          });
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((error, values) => {
        if (!error) {
          var data = values;
          that.axios.post("/admin/config/setOne/home_announcement", data).then((res) => {
            if (that.$code(res)) {
              that.$message.success("保存成功");
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
table .screenshot {
  width: 60px;
  height: auto;
  border-radius: 0;
}
.ant-table-wrapper {
  height: auto;
}
.ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-body div {
  text-align: center;
  margin: 0 20px;
}
.ant-modal-body img {
  width: 150px;
}
.ant-modal-body div {
  width: 100%;
  text-align: left;
  margin: 0;
}
.section.ant-layout .container .ant-table-wrapper {
  overflow: scroll;
}
.section.ant-layout .container .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
}
.ant-form-item-children{
    width: 100%;
    display: block;
}
.ant-input-number{width:100%}
</style>